/* overpass-100 - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/overpass-v13-latin-100.woff2') format('woff2');
}
/* overpass-100italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/overpass-v13-latin-100italic.woff2') format('woff2');
}
/* overpass-200 - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/overpass-v13-latin-200.woff2') format('woff2');
}
/* overpass-200italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/overpass-v13-latin-200italic.woff2') format('woff2');
}
/* overpass-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/overpass-v13-latin-300.woff2') format('woff2');
}
/* overpass-300italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/overpass-v13-latin-300italic.woff2') format('woff2');
}
/* overpass-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/overpass-v13-latin-regular.woff2') format('woff2');
}
/* overpass-italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/overpass-v13-latin-italic.woff2') format('woff2');
}
/* overpass-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/overpass-v13-latin-500.woff2') format('woff2');
}
/* overpass-500italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/overpass-v13-latin-500italic.woff2') format('woff2');
}
/* overpass-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/overpass-v13-latin-600.woff2') format('woff2');
}
/* overpass-600italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/overpass-v13-latin-600italic.woff2') format('woff2');
}
/* overpass-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/overpass-v13-latin-700.woff2') format('woff2');
}
/* overpass-700italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/overpass-v13-latin-700italic.woff2') format('woff2');
}
/* overpass-800 - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/overpass-v13-latin-800.woff2') format('woff2');
}
/* overpass-800italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/overpass-v13-latin-800italic.woff2') format('woff2');
}
/* overpass-900 - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/overpass-v13-latin-900.woff2') format('woff2');
}
/* overpass-900italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/overpass-v13-latin-900italic.woff2') format('woff2');
}
